import _ from 'lodash';
import type { RawParams, ResolveTypes } from '@wix/tpa-router';

import { prefetch } from 'api/feed';
import { IFeedItem } from 'api/feed/types';

import { EFilterKeys } from 'api/feed/constants';

import type { StateDeclarationFn } from 'controller/types';
import { GroupAppKey } from 'store/groups/types';
import { isOptimizationEnabled } from 'controller/ssr-optimization';

export default (function (vm, store, params) {
  const { httpClient, environment } = params.flowAPI;

  const optimization = isOptimizationEnabled(params);

  return {
    name: 'group.discussion.feed',
    url: `?${EFilterKeys.TOPICS}&${EFilterKeys.CURSOR}`,
    data: {
      sectionId: 'group',
      title: 'groups-web.discussion',
      application: GroupAppKey.FEED_APP,
    },
    params: {
      createPost: {
        type: 'bool',
        dynamic: true,
        value: null,
      },
    },
    resolve: _.compact<ResolveTypes>([
      optimization && {
        token: 'partialFeedItems',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          const {
            [EFilterKeys.CURSOR]: cursor,
            [EFilterKeys.TOPICS]: topicId,
          } = params;

          if (!isFeedAvailable) {
            return;
          }

          return httpClient
            .request(
              prefetch({
                groupId,
                params: {
                  cursor: { cursor, limit: 5 },
                  query: topicId
                    ? { filter: { [EFilterKeys.TOPICS]: topicId } }
                    : undefined,
                },
              }),
            )
            .then((response) => response.data.feedItems);
        },
      },
      {
        token: 'feedItems',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          const {
            [EFilterKeys.CURSOR]: cursor,
            [EFilterKeys.TOPICS]: topicId,
          } = params;

          if (!isFeedAvailable) {
            return;
          }

          if (!topicId) {
            return vm.feed$
              .fetch(groupId, { cursor })
              .then((response) => response.items);
          }

          return vm.feed$
            .filter(groupId, {
              filter: { [EFilterKeys.TOPICS]: topicId },
            })
            .then((response) => response.items);
        },
      },
      !environment.isMobile && {
        token: 'comments',
        deps: optimization ? ['partialFeedItems'] : ['feedItems'],
        async resolveFn(items: IFeedItem[]) {
          return vm._.comments.fetch(items);
        },
      },
    ]),
  };
} as StateDeclarationFn);
