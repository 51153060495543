import type { RequestOptions } from '@wix/http-client';

import type { ICentralFeedListResponse } from '@wix/social-groups-serverless/dist/central-feed/types';
import {
  GetFeedRequest,
  QueryFeedRequest,
} from '@wix/ambassador-social-groups-v2-central-feed-item/types';
import { serverlessBaseURL } from '../constants';

export function fetch(
  params: GetFeedRequest,
): RequestOptions<ICentralFeedListResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/groups/central-feed/fetch`,
  };
}

export function query(
  params: QueryFeedRequest,
): RequestOptions<ICentralFeedListResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/groups/central-feed/query`,
  };
}
