import * as media from 'api/media';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'media:fetch',
  async function (params: { groupId: string; isMobile: boolean }, thunkAPI) {
    const response = await thunkAPI.extra.httpClient.request(
      media.fetch(params),
    );

    return response.data;
  },
);
