import type { RequestOptions } from '@wix/http-client';
import { serverlessBaseURL } from '../constants';
import {
  ITopicsListRequest,
  ICreateTopicRequest,
  ITopic,
} from '@wix/social-groups-serverless/dist/topics/types';

export function list({
  groupId,
  withStats,
}: ITopicsListRequest): RequestOptions<ITopic[]> {
  return {
    method: 'GET',
    params: {
      withStats,
    },
    url: `/${serverlessBaseURL}/group/${groupId}/topics/list`,
  };
}

export function create({
  groupId,
  displayName,
}: ICreateTopicRequest): RequestOptions<ITopic> {
  return {
    method: 'POST',
    data: { displayName },
    url: `/${serverlessBaseURL}/group/${groupId}/topics/create`,
  };
}
