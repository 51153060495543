import type { RequestOptions } from '@wix/http-client';
import type {
  IFetchFilesRequest,
  IFileItem,
} from '@wix/social-groups-serverless/dist/files/types';
import { serverlessBaseURL } from '../constants';

export function fetch({
  groupId,
}: IFetchFilesRequest): RequestOptions<IFileItem[]> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/files`,
  };
}
