import type { RequestOptions } from '@wix/http-client';
import { serverlessBaseURL } from '../constants';

import {
  IListMemberAnswersRequest,
  IListMembersAnswersResponse,
  IJoinGroupRequest,
  IJoinGroupResponse,
  IRequestJoinGroupRequest,
  IRequestJoinGroupResponse,
  ILeaveGroupRequest,
  ILeaveGroupResponse,
  ICancelRequest,
  ICancelResponse,
  IAddAdminRoleRequest,
  IRemoveAdminRoleRequest,
  IRemoveFromGroupRequest,
  IAddMembersToGroupRequest,
} from '@wix/social-groups-serverless/dist/membership/types';
import { IGroupMember } from '@wix/social-groups-serverless/dist/members/types';

export function listMemberAnswers({
  groupId,
  memberId,
}: IListMemberAnswersRequest): RequestOptions<IListMembersAnswersResponse> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/members/${memberId}/answers`,
  };
}

export function joinGroup({
  groupId,
}: IJoinGroupRequest): RequestOptions<IJoinGroupResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/join`,
  };
}

export function requestJoinGroup({
  groupId,
  ...data
}: IRequestJoinGroupRequest): RequestOptions<IRequestJoinGroupResponse> {
  return {
    method: 'POST',
    data,
    url: `/${serverlessBaseURL}/group/${groupId}/members/request-join`,
  };
}

export function leaveGroup({
  groupId,
}: ILeaveGroupRequest): RequestOptions<ILeaveGroupResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/leave`,
  };
}

export function cancelRequest({
  groupId,
}: ICancelRequest): RequestOptions<ICancelResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/cancel-request`,
  };
}

export function addAdminRole({
  groupId,
  contactId,
}: IAddAdminRoleRequest): RequestOptions<void> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/${contactId}/add-admin-role`,
  };
}

export function removeAdminRole({
  groupId,
  contactId,
}: IRemoveAdminRoleRequest): RequestOptions<void> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/${contactId}/remove-admin-role`,
  };
}

export function removeFromGroup({
  groupId,
  memberId,
}: IRemoveFromGroupRequest): RequestOptions<void> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/members/${memberId}/remove`,
  };
}

export function addMembersToGroup(
  params: IAddMembersToGroupRequest,
): RequestOptions<IGroupMember[]> {
  return {
    method: 'POST',
    data: params,
    url: `/${serverlessBaseURL}/group/${params.groupId}/members/add-members`,
  };
}
