import type { RequestOptions } from '@wix/http-client';
import type {
  IFeedPrefetchRequest,
  IFeedPrefetchResponse,
  IFeedFetchRequest,
  IFeedFetchResponse,
  IFeedCreateRequest,
  IFeedUpdateRequest,
  IFeedItemRemoveRequest,
  IFeedGetRequest,
  IFeedGetResponse,
  IFeedReactRequest,
  IReactionsSummary,
  IFeedUnreactRequest,
  IFeedItemSubscribeRequest,
  IFeedItemSubscribeResponse,
  IFeedItemUnsubscribeRequest,
  IFeedItemUnsubscribeResponse,
  IFeedItemPinRequest,
  IFeedItemPinResponse,
  IFeedItemUnpinRequest,
  IFeedItemUnpinResponse,
  IFeedSearchRequest,
  IFeedSearchResponse,
  IFeedItemViewRequest,
  IFeedItemViewResponse,
  IFeedItem,
} from '@wix/social-groups-serverless/dist/feed/types';
import { FeedItem } from '@wix/ambassador-feed-v1-feed-item/types';
import { serverlessBaseURL } from '../constants';

export function prefetch({
  groupId,
  params,
}: IFeedPrefetchRequest): RequestOptions<IFeedPrefetchResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/prefetch`,
  };
}

export function fetch({
  groupId,
  params,
}: IFeedFetchRequest): RequestOptions<IFeedFetchResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/fetch`,
  };
}

export function create({
  groupId,
  request,
}: IFeedCreateRequest): RequestOptions<IFeedItem> {
  return {
    data: request,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/create`,
  };
}

export function update({
  groupId,
  request,
}: IFeedUpdateRequest): RequestOptions<IFeedItem> {
  return {
    data: request,
    method: 'PATCH',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/update`,
  };
}

export function remove({
  groupId,
  feedItemId,
}: IFeedItemRemoveRequest): RequestOptions<FeedItem> {
  return {
    method: 'DELETE',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}`,
  };
}

export function get({
  groupId,
  feedItemId,
}: IFeedGetRequest): RequestOptions<IFeedGetResponse> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}`,
  };
}

export function react({
  groupId,
  ...data
}: IFeedReactRequest): RequestOptions<IReactionsSummary> {
  return {
    data,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/react`,
  };
}

export function unreact({
  groupId,
  ...data
}: IFeedUnreactRequest): RequestOptions<IReactionsSummary> {
  return {
    data,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/unreact`,
  };
}

export function subscribe({
  groupId,
  feedItemId,
}: IFeedItemSubscribeRequest): RequestOptions<IFeedItemSubscribeResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}/subscribe`,
  };
}

export function unsubscribe({
  groupId,
  feedItemId,
}: IFeedItemUnsubscribeRequest): RequestOptions<IFeedItemUnsubscribeResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}/unsubscribe`,
  };
}

export function pin({
  groupId,
  feedItemId,
}: IFeedItemPinRequest): RequestOptions<IFeedItemPinResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}/pin`,
  };
}

export function unpin({
  groupId,
  feedItemId,
}: IFeedItemUnpinRequest): RequestOptions<IFeedItemUnpinResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}/unpin`,
  };
}

export function search({
  groupId,
  params,
}: IFeedSearchRequest): RequestOptions<IFeedSearchResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/search`,
  };
}

export function view({
  groupId,
  feedItemId,
}: IFeedItemViewRequest): RequestOptions<IFeedItemViewResponse> {
  return {
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/feed/${feedItemId}/view`,
  };
}
