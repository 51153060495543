import { serverlessBaseURL } from '../constants';
import type { RequestOptions } from '@wix/http-client';
import type {
  IGetMemberRequest,
  IMember,
  IFetchGroupMemberRequest,
  IGroupMember,
  IQueryGroupMembersRequest,
  IQueryGroupMembersResponse,
  IQueryNonGroupMembersRequest,
  IQueryNonGroupMembersResponse,
  IListGroupMembersRequest,
} from '@wix/social-groups-serverless/dist/members/types';

export function getMember({
  memberId,
}: IGetMemberRequest): RequestOptions<IMember> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/members/${memberId}`,
  };
}

export function getGroupMember({
  groupId,
  memberId,
}: IFetchGroupMemberRequest): RequestOptions<IGroupMember> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/group-members/${memberId}`,
  };
}

export function queryGroupMembers({
  groupId,
  ...data
}: IQueryGroupMembersRequest): RequestOptions<IQueryGroupMembersResponse> {
  return {
    data,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/group-members/query`,
  };
}

export function queryNonGroupMembers({
  groupId,
  ...data
}: IQueryNonGroupMembersRequest): RequestOptions<IQueryNonGroupMembersResponse> {
  return {
    data,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/non-group-members/query`,
  };
}

export function listGroupMembers({
  groupId,
  limit,
  offset,
}: IListGroupMembersRequest): RequestOptions<IGroupMember[]> {
  return {
    method: 'GET',
    params: {
      limit,
      offset,
    },
    url: `/${serverlessBaseURL}/group/${groupId}/group-members`,
  };
}
