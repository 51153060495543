import type { RawParams } from '@wix/tpa-router';

import type { StateDeclarationFn } from 'controller/types';

import type { IFeedItem } from 'api/feed/types';
import { GroupAppKey } from 'store/groups/types';

export default (function (vm, store, params) {
  return {
    name: 'group.discussion.post',
    url: '/:feedItemId?commentId',
    data: {
      sectionId: 'group',
      application: GroupAppKey.FEED_APP,
    },
    resolve: [
      {
        token: 'feedItem',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          if (isFeedAvailable) {
            return vm.feed$.get(groupId, params.feedItemId);
          }
        },
      },
      {
        token: 'comments',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        async resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          if (isFeedAvailable) {
            return vm._.comments.fetch([
              {
                feedItemId: params.feedItemId,
                applicationContext: { contextId: groupId },
              } as IFeedItem,
            ]);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
