import type { RequestOptions } from '@wix/http-client';

import type {
  IGetGroupRequest,
  IGetGroupResponse,
  ICreateGroupRequest,
  ICreateGroupResponse,
  IUpdateGroupRequest,
  IUpdateGroupResponse,
  IListPendingRequestsRequest,
  IListPendingRequestsResponse,
  IGetPendingRequestsCountRequest,
  IQueryGroupsRequest,
  IQueryGroupsResponse,
  IQueryRecommendedGroupsRequest,
} from '@wix/social-groups-serverless/dist/groups/types';

import { serverlessBaseURL } from '../constants';

export function getGroup({
  id,
  autoInviteId,
}: IGetGroupRequest): RequestOptions<IGetGroupResponse> {
  return {
    method: 'GET',
    params: {
      autoInviteId,
    },
    url: `/${serverlessBaseURL}/group/${id}`,
  };
}

export function create(
  data: ICreateGroupRequest,
): RequestOptions<ICreateGroupResponse> {
  return {
    method: 'POST',
    data,
    url: `/${serverlessBaseURL}/group/create`,
  };
}

export function updateGroup({
  group,
  groupId,
}: IUpdateGroupRequest): RequestOptions<IUpdateGroupResponse> {
  return {
    method: 'PATCH',
    data: group,
    url: `/${serverlessBaseURL}/group/${groupId}`,
  };
}

export function listPendingRequests({
  groupId,
}: IListPendingRequestsRequest): RequestOptions<IListPendingRequestsResponse> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/list-pending-requests`,
  };
}

export function getPendingRequestsCount({
  groupId,
}: IGetPendingRequestsCountRequest): RequestOptions<number> {
  return {
    method: 'GET',
    url: `/${serverlessBaseURL}/group/${groupId}/pending-requests-count`,
  };
}

export function query(
  data: IQueryGroupsRequest,
): RequestOptions<IQueryGroupsResponse> {
  return {
    method: 'POST',
    data,
    url: `/${serverlessBaseURL}/groups/query`,
  };
}

export function queryRecommended(
  data: IQueryRecommendedGroupsRequest,
): RequestOptions<IQueryGroupsResponse> {
  return {
    method: 'POST',
    data,
    url: `/${serverlessBaseURL}/groups/query-recommended`,
  };
}
