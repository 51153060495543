import type { RequestOptions } from '@wix/http-client';

import type {
  IFetchRequest,
  IFetchResponse,
} from '@wix/social-groups-serverless/dist/media/types';

import { serverlessBaseURL } from '../constants';

export function fetch({
  groupId,
  isMobile,
}: IFetchRequest): RequestOptions<IFetchResponse> {
  return {
    method: 'GET',
    params: {
      isMobile,
    },
    url: `/${serverlessBaseURL}/group/${groupId}/media/fetch`,
  };
}
